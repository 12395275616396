<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true" :model="formInline">
				<el-form-item label="商户名称">
					<el-input v-model="formInline.name" clearable placeholder="请输入商户名称"></el-input>
				</el-form-item>
				<el-form-item label="商户联系电话">
					<el-input v-model="formInline.phone" clearable placeholder="请输入商户联系电话"></el-input>
				</el-form-item>
				<el-form-item label="推荐社区">
					<el-select v-model="formInline.communityCode" filterable clearable placeholder="请选择">
						<el-option v-for="item in communitys" :key="item.levelcode" :label="item.name"
							:value="item.levelcode">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch">查询</el-button>
					<el-button type="primary" @click="addRow">新增</el-button>
					
					<el-button type="default" icon="el-icon-download" @click="exportXcle">导出商户信息报表</el-button>
				</el-form-item>
			</el-form>
		</div>


		<el-table v-show="false" :data="tableDataExport" stripe id="exportTable">
      <el-table-column label="序号" type="index" :index="indexMethod">
			</el-table-column>
			<el-table-column label="商户名称" prop="name" width="200" show-overflow-tooltip>
			</el-table-column>
			<el-table-column label="联系人" prop="contact">
			</el-table-column>
			<el-table-column label="联系电话" prop="phone">
			</el-table-column>
			<el-table-column label="推荐社区" prop="community_name">
			</el-table-column>
			<el-table-column label="商户地址" prop="address">
			</el-table-column>
			<el-table-column label="取货地址">
				<template slot-scope="scope">
					<span v-for="(item,index) in getAddressList(scope.row.shipping_address)" :key="item.address">
						{{index + 1}} 、{{item.address}} ;
					</span>
				</template>
			</el-table-column>
			<el-table-column label="社会信用代码" prop="credit_code">
			</el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					<span v-if="scope.row.status === '0'">正常</span>
					<span v-if="scope.row.status === '1'">暂停</span>
				</template>
			</el-table-column>
    </el-table>

		
		<el-table :data="tableData" style="width: 100%">
			<el-table-column label="序号" type="index" :index="indexMethod">
			</el-table-column>
			<el-table-column label="商户名称" prop="name" width="200" show-overflow-tooltip>
			</el-table-column>
			<el-table-column label="联系人" prop="contact">
			</el-table-column>
			<el-table-column label="联系电话" prop="phone">
			</el-table-column>
			<el-table-column label="推荐社区" prop="community_name">
			</el-table-column>
			<el-table-column label="商户地址" prop="address">
			</el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					<span v-if="scope.row.status === '0'">正常</span>
					<span v-if="scope.row.status === '1'">暂停</span>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="280">
				<template slot-scope="scope">
					<el-button @click="lookRow(scope.row)" type="text" size="small" icon="el-icon-view">查看</el-button>
					<el-button @click="editRow(scope.row)" type="text" size="small" icon="el-icon-edit">修改</el-button>
					<el-button v-if="scope.row.status === '0'" @click="changeStatus('0', scope.row.id)" type="text"
						size="small" icon="el-icon-remove-outline">暂停</el-button>
					<el-button v-if="scope.row.status === '1'" @click="changeStatus('1', scope.row.id)" type="text"
						size="small" icon="el-icon-circle-check">恢复</el-button>
					<el-button @click="deleteRow(scope.row)" type="text" size="small" icon="el-icon-delete">删除
					</el-button>
					<el-button @click="resetPwd(scope.row.id)" type="text" size="small" icon="el-icon-unlock">重置
					</el-button>
				</template>
			</el-table-column>
		</el-table>

		<div class="pagebox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalRow">
			</el-pagination>
		</div>


		<el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="900px">
			<el-form :model="form" :rules="rules" ref="form" label-width="120px" style="width: 94%;"
				:disabled="viewFlag">
				<el-form-item label="商户名称" prop="name">
					<el-input v-model="form.name" clearable placeholder="请输入商户名称"></el-input>
				</el-form-item>
				<el-form-item label="商户类型" prop="type">
					<el-select v-model="form.type" clearable placeholder="请选择活动区域">
						<el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="联系人" prop="contact">
					<el-input v-model="form.contact" clearable placeholder="请输入联系人"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phone">
					<el-input v-model="form.phone" clearable placeholder="请输入联系电话"></el-input>
				</el-form-item>
				<el-form-item label="推荐社区" prop="communityCode">
					<el-select v-model="form.communityCode" filterable clearable placeholder="请选择">
						<el-option v-for="item in communitys" :key="item.levelcode" :label="item.name"
							:value="item.levelcode">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="地址" prop="address">
					<el-input v-model="form.address" placeholder="请选择地址">
						<el-button v-if="!viewFlag" slot="append" icon="el-icon-location-outline"
							@click="openMapFn('shop-address','address', 'coordinate')"></el-button>
					</el-input>
				</el-form-item>

				<el-form-item v-for="(item, index) in form.shippingAddress" :label="'取货地址' + (index+1)" :key="item.key"
					:prop="'shippingAddress.' + index + '.address'" :rules="{
              required: true, message: '取货地址不能为空', trigger: 'blur'
            }">
					<el-input v-model="item.address" placeholder="请选择地址" style="width: 80%; margin-right: 10px;">
						<el-button slot="append" icon="el-icon-location-outline" @click="openMapFn('shop-address', 'address', 'coordinate', {
                'obj': 'shippingAddress',
                'idx': index
              })"></el-button>
					</el-input>
					<el-button @click.prevent="addItem" v-if="index === 0">+ 取货地址</el-button>
					<el-button @click.prevent="removeItem(item)" v-if="index !== 0">- 取货地址</el-button>
				</el-form-item>

				<el-form-item label="社会信用代码" clearable prop="creditCode">
					<el-input v-model="form.creditCode" placeholder="请输入社会信用代码"></el-input>
				</el-form-item>
				<el-form-item label="坐标" prop="coordinate" v-show="false">
					<el-input v-model="form.coordinate" clearable placeholder="请输入坐标"></el-input>
				</el-form-item>
				<el-form-item label="商铺介绍" prop="intro">
					<vue-editor v-model="form.intro" id="container" @image-added="handleImageAdded"
						useCustomImageHandler> </vue-editor>
				</el-form-item>
				<el-form-item label="商铺照片" prop="picUrl">
					<el-upload action="#" :class="{shopfacehide: shopfacehideUploadEidt}" :auto-upload="false"
						accept="image/*" :show-file-list="true" list-type="picture-card" :on-change="shopfaceImgchange"
						:on-progress="imgloading" :file-list="shopfaceList">
						<div slot="file" slot-scope="{file}">
							<img :src="file.url" style="width:100%;height:146px !important">
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="bigImg(file)"><i
										class="el-icon-zoom-in"></i></span>
								<span class="el-upload-list__item-delete" @click="shopfaceImgRemove(file)"
									v-show="!viewFlag">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="商铺画廊" prop="gallery">
					<el-upload action="#" :auto-upload="false" accept="image/*" :class="{ hualanghide: viewFlag}"
						:show-file-list="true" list-type="picture-card" :on-change="galleryChange"
						:on-progress="imgloading" :file-list="galleryList">
						<div slot="file" slot-scope="{file}">
							<img :src="file.url" style="width:100%;height:146px !important">
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="bigImg(file)"><i
										class="el-icon-zoom-in"></i></span>
								<span class="el-upload-list__item-delete" @click="galleryRemove(file)"
									v-show="!viewFlag">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="营业执照图片" prop="licencesUrl">
					<el-upload action="#" :class="{yyzzhide: yyzzhideUploadEidt}" :auto-upload="false" accept="image/*"
						:show-file-list="true" list-type="picture-card" :on-change="yingyezhizhaoImgchange"
						:on-progress="imgloading" :file-list="yingyezhizhaoList">
						<div slot="file" slot-scope="{file}">
							<img :src="file.url" style="width:100%;height:146px !important">
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="bigImg(file)"><i
										class="el-icon-zoom-in"></i></span>
								<span class="el-upload-list__item-delete" @click="yingyezhizhaoImgRemove(file)"
									v-show="!viewFlag">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="密码" prop="password" v-show="false">
					<el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" v-show="!viewFlag">
				<el-button v-show="editType === 'add'" @click="resetForm('form')">重置</el-button>
				<el-button type="primary" @click="submitForm('form')">确定提交</el-button>
			</div>
		</el-dialog>


		<event-map ref="eventMap" @getEventMapData="getEventMapData"></event-map>


		<el-dialog :visible.sync="imgDialogVisible" title="图片预览" append-to-body>
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	import FileSaver from 'file-saver'
	import XLSX from 'xlsx'
	import {
		upLoadFileObj
	} from '@/api/itemCategory'
	import {
		getUserArea
	} from '@/api/system'
	import {
		VueEditor
	} from 'vue2-editor'
	import eventMap from './eventmap.vue'
	import {
		getShopsPage,
		getShopInfo,
		addShop,
		updateShop,
		deleteShop,
		resumeShop,
		suspendShop,
		resetPassword,
		getDictItem
	} from '@/api/merchantManage'
	import {
		sureMessageBox
	} from '@/utils/common'
	export default {
		name: "merchantmanage",
		components: {
			eventMap,
			VueEditor
		},
		props: {},
		data() {
			return {
				typeOptions: [],
				title: '',
				formInline: {
					name: '',
					phone: '',
					communityCode: ''
				},
				tableData: [],
				currentPage: 1,
				totalRow: 0,
				pageSize: 10,
				dialogImageUrl: '',
				imgDialogVisible: false,
				form: {
					name: '',
					type: '',
					phone: '',
					contact: '',
					address: '',
					creditCode: '',
					coordinate: '',
					intro: '',
					picUrl: '',
					gallery: '',
					licencesUrl: '',
					communityCode:'',
					password: '888888',
					shippingAddress: [{
						address: '',
						coordinate: '',
						key: Date.now()
					}]
				},
				formLabelWidth: '120px',
				dialogFormVisible: false,
				editType: 'add',
				galleryList: [],
				shopfaceList: [],
				shopfacehideUploadEidt: false,
				yingyezhizhaoList: [],
				yyzzhideUploadEidt: false,
				shopId: '',
				viewFlag: false, //查看的标识符
				communitys: [],
				tableDataExport: [],
				rules: {
					name: [{
						required: true,
						message: '请输入商户名称',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择商户类型',
						trigger: 'change'
					}],
					phone: [{
						required: true,
						message: '请输入联系电话',
						trigger: 'blur'
					}],
					contact: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请选择地址',
						trigger: 'change'
					}],
					creditCode: [{
						required: true,
						message: '请输入社会信用代码',
						trigger: 'blur'
					}],
					intro: [{
						required: true,
						message: '请输入商铺介绍',
						trigger: 'change'
					}],
					picUrl: [{
						required: true,
						message: '请上传商铺照片',
						trigger: 'change'
					}],
					gallery: [{
						required: true,
						message: '请上传商铺画廊照片',
						trigger: 'change'
					}],
					licencesUrl: [{
						required: true,
						message: '请上传营业执照图片',
						trigger: 'change'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					communityCode: [{
						required: true,
						message: '请选择推荐社区',
						trigger: 'blur'
					}]


				}
			}
		},
		watch: {},
		created() {},
		mounted() {
			this.getList();
			this.getTypeList();
			getUserArea({
				userid: this.GLOBAL.adminId(),
			}).then(res => {
				this.communitys = res.detail;
			})
		},
		computed: {},
		methods: {
			getAddressList(obj){
				if(obj){
					return (
						JSON.parse(obj).shippingAddress
					);
				}else{
					return []
				}
				
			},
			//获取商户类型
			getTypeList() {
				getDictItem({
					type: 'shop_type'
				}).then(res => {
					if (res.result == 200) {
						this.typeOptions = res.detail;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//导出商户列表
			exportXcle(){
				getShopsPage({
					name: this.formInline.name,
					phone: this.formInline.phone,
					communityCode: this.formInline.communityCode,
					pageSize: 999999999,
        	pageNum: 1,
				}).then(res => {
					if (res.result == 200) {
						let detail = res.detail;
						this.tableDataExport = detail.list;
						let downloadTimer = setTimeout(()=>{
							clearTimeout(downloadTimer);
							let xlsxParam = { raw: true };
							let wb = XLSX.utils.table_to_book(document.querySelector("#exportTable"),xlsxParam);
							let wbout = XLSX.write(wb, {
								bookType: "xlsx",
								bookSST: true,
								type: "array",
							});
							try {
								FileSaver.saveAs(
									new Blob([wbout], { type: "application/octet-stream" }),"商户信息报表.xlsx"
								);
							} catch (e) {
								if (typeof console !== "undefined") console.log(e, wbout);
							}
							return wbout;
						},10)
					} else {
						this.$message.error(res.description);
					}
				});
			},
			//获取商户列表
			getList() {
				getShopsPage({
					name: this.formInline.name,
					phone: this.formInline.phone,
					communityCode: this.formInline.communityCode,
					pageSize: this.pageSize,
					pageNum: this.currentPage
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						this.tableData = res.detail.list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//图片预览或附件下载
			bigImg(file) {
				// console.log(file)
				//通过id获取图片或链接地址
				let url = file.url;
				this.dialogImageUrl = url;
				this.imgDialogVisible = true;
			},
			//上传图片中
			imgloading(event) {
				console.log(event)
				this.$message.danger("文件上传中请勿重复上传");
			},
			//打开地图选地址
			//id是地图的id，address指定的时接收地址值的对象，coordinate指定的时接收经纬度值的对象
			//type如果有值，说明是给对象赋值
			openMapFn(id, address, coordinate, type) {
				this.$refs.eventMap.dialogMapVisible = true;
				this.$refs.eventMap.mapVid = id;
				this.$refs.eventMap.params = {
					address: address,
					coordinate: coordinate,
					type: type ? type : null
				}

			},
			//获取地址和经纬度
			getEventMapData(data) {
				console.log(data)
				//params.address 和 params.coordinate是对应的；
				if (data.params.type) {
					console.log(this.form[data.params.type.obj][data.params.type.idx][data.params.address])
					this.form[data.params.type.obj][data.params.type.idx][data.params.address] = data.address;
					this.form[data.params.type.obj][data.params.type.idx][data.params.coordinate] = data.lng + ',' + data
						.lat;
				} else {
					this.form[data.params.address] = data.address;
					this.form[data.params.coordinate] = data.lng + ',' + data.lat;
				}
			},
			//查看详情
			lookRow(row) {
				this.editRow(row);
				this.viewFlag = true;
				this.title = '查看商户详情'
				/* getShopInfo({
				  shopId:row.id 
				}).then(res=>{
				  if(res.result == 200){
				    console.log(res)
				  }else{
				    this.$message.warning(res.description)
				  }
				}) */
			},
			//删除商户
			deleteRow(row) {
				sureMessageBox('是否确认删除该商户信息？').then(res => {
					deleteShop({
						shopId: row.id
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功')
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			//自定义序号
			indexMethod(index) {
				return (this.currentPage - 1) * this.pageSize + (index + 1);
			},
			//搜索
			onSearch() {
				this.currentPage = 1;
				this.getList();
			},
			//添加商户信息
			addRow() {
				this.title = '新增商户详情'
				this.viewFlag = false;
				this.editType = 'add'
				this.dialogFormVisible = true;
				this.resetForm('form')

			},
			//修改商户信息
			editRow(row) {
				this.title = '修改商户详情'
				this.viewFlag = false;
				this.editType = 'update'
				this.shopId = row.id;
				this.dialogFormVisible = true;
				this.form = {
					name: row.name,
					type: row.type,
					phone: row.phone,
					contact: row.contact,
					address: row.address,
					creditCode: row.credit_code,
					coordinate: row.coordinate,
					intro: row.intro,
					picUrl: row.pic_url,
					gallery: row.gallery,
					licencesUrl: row.licences_url,
					password: row.password,
					communityCode:row.community_code,
					shippingAddress: row.shipping_address ? (JSON.parse(row.shipping_address).shippingAddress) : [{
						address: '',
						coordinate: '',
						key: Date.now()
					}]
				}

				let gallery = this.form.gallery.split(',')
				this.galleryList = [];
				gallery.forEach((item, index) => {
					this.galleryList.push({
						name: index,
						url: item
					})
				});

				this.shopfaceList = [];
				this.shopfaceList.push({
					name: 'shopface',
					url: row.pic_url
				})
				this.shopfacehideUploadEidt = true;

				this.yingyezhizhaoList = [];
				this.yingyezhizhaoList.push({
					name: 'yingyezhizhao',
					url: row.licences_url
				});
				this.yyzzhideUploadEidt = true;
			},
			//切换商户状态
			changeStatus(type, shopId) {
				let tip = "";
				let apiName = null
				if (type === '0') {
					tip = "商户状态暂停时，该商户的所有商品不可兑换，是否确认暂停商户运营？"
					apiName = suspendShop;
				}
				if (type === '1') {
					tip = "是否确认恢复商户运营？"
					apiName = resumeShop
				}
				sureMessageBox(tip).then(res => {
					apiName({
						shopId
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功')
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			//重置密码
			resetPwd(shopId) {
				sureMessageBox('是否确认重置密码？').then(res => {
					resetPassword({
						shopId
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('密码重置成功')
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			//商铺画廊图片上传
			galleryChange(file, fileList) {
				let formData = new FormData()
				formData.append('file', file.raw);
				upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId(),
					contentType: 'image/*'
				}).then((res) => {
					if (res.result == 200) {
						this.$message.success('图片上传成功')
						let url = res.detail[0].path;
						this.galleryList.push({
							name: res.detail[0].id,
							url
						});
						let imgs = [];
						this.galleryList.forEach(item => {
							imgs.push(item.url)
						})
						this.form.gallery = imgs.join(',');
					} else {
						this.$message.error('图片上传失败')
					}
				})
			},
			//商户画廊图片删除
			galleryRemove(file) {
				console.log(file)
				console.log(this.galleryList)
				this.galleryList.map((item, index) => {
					if (item.name == file.name) {
						this.galleryList.splice(index, 1)
					}
				})
			},
			//商铺照片上传
			shopfaceImgchange(file, fileList) {
				this.shopfacehideUploadEidt = fileList.length > 0;
				let formData = new FormData()
				formData.append('file', file.raw);
				upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId(),
					contentType: 'image/*'
				}).then((res) => {
					if (res.result == 200) {
						this.$message.success('图片上传成功')
						let url = res.detail[0].path;
						this.shopfaceList.push({
							name: res.detail[0].id,
							url
						})
						this.shopfacehideUploadEidt = true;
						this.form.picUrl = url;
					} else {
						this.$message.error('图片上传失败')
					}
				})
			},
			//商铺照片删除
			shopfaceImgRemove(file) {
				this.shopfaceList.map((item, index) => {
					if (item.name == file.name) {
						this.shopfaceList.splice(index, 1)
					}
				})
				this.form.picUrl = '';
				this.shopfacehideUploadEidt = this.shopfaceList.length > 0
			},
			//营业执照图片上传
			yingyezhizhaoImgchange(file, fileList) {
				this.yyzzhideUploadEidt = fileList.length > 0;
				let formData = new FormData()
				formData.append('file', file.raw);
				upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId(),
					contentType: 'image/*'
				}).then((res) => {
					if (res.result == 200) {
						this.$message.success('图片上传成功')
						let url = res.detail[0].path;
						this.yingyezhizhaoList.push({
							name: res.detail[0].id,
							url
						})
						this.yyzzhideUploadEidt = true;
						this.form.licencesUrl = url;
					} else {
						this.$message.error('图片上传失败')
					}
				})
			},
			//营业执照图片删除
			yingyezhizhaoImgRemove(file) {
				this.yingyezhizhaoList.map((item, index) => {
					if (item.name == file.name) {
						this.yingyezhizhaoList.splice(index, 1)
					}
				})
				this.form.licencesUrl = ''
				this.yyzzhideUploadEidt = this.yingyezhizhaoList.length > 0
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//多图片时
						let imgs = [];
						this.galleryList.forEach(item => {
							imgs.push(item.url)
						})
						this.form.gallery = imgs.join(',');

						let paramsVal = {
							name: this.form.name,
							type: this.form.type,
							phone: this.form.phone,
							contact: this.form.contact,
							address: this.form.address,
							creditCode: this.form.creditCode,
							coordinate: this.form.coordinate,
							intro: this.form.intro,
							picUrl: this.form.picUrl,
							gallery: this.form.gallery,
							licencesUrl: this.form.licencesUrl,
							password: this.form.password,
							communityCode:this.form.communityCode,
							shippingAddresses: this.form.shippingAddress
						}

						/* let dataVal = {
							shippingAddress: this.form.shippingAddress
						} */

						if (this.editType === 'update') {
							//修改
							paramsVal.id = this.shopId;
							sureMessageBox('是否确认修改商户信息？').then(res => {
								updateShop(paramsVal).then(res => {
									if (res.result == 200) {
										this.$message.success('修改成功')
										this.getList();
										this.dialogFormVisible = false;
									} else {
										this.$message.warning(res.description)
									}
								})
							}).catch(err => {
								console.log(err)
							})

						} else {
							//新增
							addShop(paramsVal).then(res => {
								if (res.result == 200) {
									this.$message.success('添加成功')
									this.currentPage = 1;
									this.getList();
									this.dialogFormVisible = false;
								} else {
									this.$message.warning(res.description)
								}
							})
						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.form = {
					name: '',
					type: '',
					phone: '',
					contact: '',
					address: '',
					creditCode: '',
					coordinate: '',
					intro: '',
					picUrl: '',
					gallery: '',
					licencesUrl: '',
					password: '888888',
					shippingAddress: [{
						address: '',
						coordinate: '',
						key: Date.now()
					}]

				}
				if (this.$refs[formName] !== undefined) {
					this.$refs[formName].resetFields();
				}

				this.galleryList = [];
				this.shopfacehideUploadEidt = false;
				this.yyzzhideUploadEidt = false;
				this.shopfaceList = [];
				this.yingyezhizhaoList = [];
				this.shopId = '';
			},
			//富文本上传图片
			handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
				var formData = new FormData();
				formData.append("file", file);

				//上传图片
				upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						let url = res.detail[0].path
						Editor.insertEmbed(cursorLocation, "image", url);
						resetUploader();
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//添加取货地址
			addItem() {
				console.log(this.form.shippingAddress)
				this.form.shippingAddress.push({
					address: '',
					coordinate: '',
					key: Date.now()
				});
			},
			//删除取货地址
			removeItem(item) {
				if (this.form.shippingAddress.length == 1) {
					this.$message.warning('至少保留一个取货地址');
					return false;
				}
				var index = this.form.shippingAddress.indexOf(item)
				if (index !== -1) {
					this.form.shippingAddress.splice(index, 1)
				}
			},

		}
	}
</script>

<style lang="scss" scoped>
	.searchbox {
		margin: 20px 10px;
	}


	.pagebox {
		margin: 20px 0;
		text-align: right;
	}

	// 隐藏上传按钮
	::v-deep {

		.yyzzhide,
		.shopfacehide,
		.hualanghide {
			&>div.el-upload--picture-card {
				display: none;
			}
		}

	}
</style>
